import axios from 'axios';
export default {

    state: {
        generalExamData: localStorage.getItem('generalExamData') ? JSON.parse(localStorage.getItem('generalExamData')) : null,
        studentExamData: localStorage.getItem('studentExamData') ? JSON.parse(localStorage.getItem('studentExamData')) : null,
        answeredQuestions: localStorage.getItem('answeredQuestions') ? JSON.parse(localStorage.getItem('answeredQuestions')) : [],
        skippedQuestions: localStorage.getItem('skippedQuestions') ? JSON.parse(localStorage.getItem('skippedQuestions')) : [],
        favoriteQuestions: localStorage.getItem('favoriteQuestions') ? JSON.parse(localStorage.getItem('favoriteQuestions')) : [],
        results: localStorage.getItem('results') ? JSON.parse(localStorage.getItem('results')) : {},
        examDuration: localStorage.getItem('examDuration') ? JSON.parse(localStorage.getItem('examDuration')) : '',
    },
    getters: {
        getGeneralExamData: state => state.generalExamData,
        getStudentExamData: state => state.studentExamData,
        getAnswerQuestion: state => state.answeredQuestions,
        getskippedQuestions: state => state.skippedQuestions,
        getFavoriteQuestions: state => state.favoriteQuestions,
        getResults: state => state.results,
        getExamDuration: state => state.examDuration,
    },
    mutations: {
        setGeneralExamData(state, data) {
            state.generalExamData = data;
        },
        setStudentExamData(state, data) {
            state.studentExamData = data;
        },
        setAnsweredQuestions(state, answeredQuestions) {
            state.answeredQuestions = answeredQuestions;
            localStorage.setItem('answeredQuestions', JSON.stringify(state.answeredQuestions));
        },
        setSkippedQuestions(state, skippedQuestions) {
            state.skippedQuestions = skippedQuestions;
            localStorage.setItem('skippedQuestions', JSON.stringify(state.skippedQuestions));
        },
        setFavoriteQuestions(state, favoriteQuestions) {
            state.favoriteQuestions = favoriteQuestions;
            localStorage.setItem('favoriteQuestions', JSON.stringify(state.favoriteQuestions));
        },
        setResultsQuestions(state, results) {
            state.results = results;
            localStorage.setItem('results', JSON.stringify(state.results));
        },
        setExamDuration(state, examDuration) {
            state.examDuration = examDuration;
            localStorage.setItem('examDuration', JSON.stringify(state.examDuration));
        },
        RESET_ANSWERED_QUESTIONS(state) {
            state.answeredQuestions = [];
        },
        RESET_STUDENT_EXAM_DATA(state) {
            state.studentExamData = {};
        },
        RESET_GENERAL_EXAM_DATA(state) {
            state.generalExamData = {};
        },
        RESET_SKIPPED_QUESTIONS(state) {
            state.skippedQuestions = [];
        },
        RESET_FAVORITE_QUESTIONS(state) {
            state.favoriteQuestions = [];
        },
        RESET_RESULTS(state) {
            state.results = {};
        },
        RESET_ExamDuration(state) {
            state.examDuration = {};
        }
    },
    actions: {
        updateGeneralExamData({ commit }, data) {
            commit('setGeneralExamData', data);
        },
        updateStudentExamData({ commit }, data) {
            commit('setStudentExamData', data);
        },
        updateAnsweredQuestions({ commit }, data) {
            commit('setAnsweredQuestions', data);
        },
        updateSkippedQuestions({ commit }, data) {
            commit('setSkippedQuestions', data);
        },
        updateFavoriteQuestions({ commit }, data) {
            commit('setFavoriteQuestions', data);
        },
        updateResultsQuestions({ commit }, data) {
            commit('setResultsQuestions', data);
        },
        updateExamDuration({ commit }, data) {
            commit('setExamDuration', data);
        },
        clearZidneyExamData({ commit }) {
            commit('RESET_ANSWERED_QUESTIONS');
            commit('RESET_STUDENT_EXAM_DATA');
            commit('RESET_GENERAL_EXAM_DATA');
            commit('RESET_SKIPPED_QUESTIONS');
            commit('RESET_FAVORITE_QUESTIONS');
            commit('RESET_RESULTS');
            commit('RESET_ExamDuration');
        },
        async submitExam({ state, commit }) {
            const student_exam_id = state.studentExamData.id;
            const answers = state.answeredQuestions;
            try {
                const response = await axios.post('student-exams/result', { student_exam_id, answers });

                const { exam, student_exam, messages,stats } = response.data.data;
                const results = {
                    exam,
                    student_exam,
                    messages,
                    stats
                };

                commit('setResultsQuestions', results);

            } catch (error) {

                console.error(error);
            }
        },
        async submitSelfExam({ state, commit }) {
            const self_student_exam_id = state.studentExamData.id;
            const answers = state.answeredQuestions;
            
            try {
                const response = await axios.post('self-student-exams/result', { self_student_exam_id, answers });

                const { self_student_exam , stats } = response.data.data;
                const results = {
                    self_student_exam,
                    stats
                };

                commit('setResultsQuestions', results);

            } catch (error) {

                console.error(error);
            }
        }
    }
}