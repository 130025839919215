export default {
  "chgProfileData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الملف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chgProfileData"])}
  },
  "accountSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات الحساب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accountSettings"])}
  },
  "myInvoices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فواتيرى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myInvoices"])}
  },
  "logout": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout"])}
  },
  "signIn": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signIn"])}
  },
  "fullName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالكامل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسم بالكامل"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لإدخال الاسم كاملاً مع اللقب"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fullName"])}
  },
  "password": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لإدخال تعريف كلمة المرور الخاصة بالمستخدم  للدخول في النظام  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])}
  },
  "countries": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدول"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countries"])}
  },
  "editCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الدوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editCountry"])}
  },
  "countryInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الدوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryInfo"])}
  },
  "search": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])}
  },
  "ID": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعرف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  },
  "countryName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدوله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم الدوله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryName"])}
  },
  "countryCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود الدوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود الدوله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال كود الدوله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryCode"])}
  },
  "countryStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الدوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الدوله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال حاله الدوله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryStatus"])}
  },
  "operations": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمليات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operations"])}
  },
  "edit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])}
  },
  "toggleStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الحاله "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toggleStatus"])}
  },
  "noDataFound": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noDataFound"])}
  },
  "addCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضاافه دوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCountry"])}
  },
  "cancel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])}
  },
  "return": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["return"])}
  },
  "allRightsReserved": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allRightsReserved"])}
  },
  "terms": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms"])}
  },
  "requiredMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة مطلوبة لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requiredMsg"])}
  },
  "minLengthMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى للطول المسموح به هو 2 لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minLengthMsg"])}
  },
  "maxLengthMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للطول المسموح به هو 200لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsg"])}
  },
  "maxLengthMsgTextArea": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للطول المسموح به هو 5000 لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsgTextArea"])}
  },
  "alertLangValidation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال جميع اللغاات المطلوبه من خلال تبويبات الترجمه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alertLangValidation"])}
  },
  "minValue": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقل قيمة ممكنة هي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minValue"])}
  },
  "maxLengthMsgMobile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للطول المسموح به هو 20 لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsgMobile"])}
  },
  "minLengthMsgMobile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى للطول المسموح به هو 7 لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minLengthMsgMobile"])}
  },
  "numericValidationMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال ارقام فقط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numericValidationMsg"])}
  },
  "depHasEducational": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم مرتبط بمرحلة دراسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["depHasEducational"])}
  },
  "emailInvailed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني غير صحيح"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["emailInvailed"])}
  },
  "alphaMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل يقبل اللغة الإنجليزية فقط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alphaMsg"])}
  },
  "slugValidation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل يقبل اللغه الانجليزيه و الارقام و الرموز فقط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slugValidation"])}
  },
  "noOptionsText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القائمة فارغة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noOptionsText"])}
  },
  "noResultsText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على نتائج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noResultsText"])}
  },
  "invalidUsernameOrPass": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد أدخلت اسم مستخدم أو كلمة مرور غير صالحة!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalidUsernameOrPass"])}
  },
  "addCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه عمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCurrency"])}
  },
  "editCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editCurrency"])}
  },
  "currencyInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyInfo"])}
  },
  "currencies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencies"])}
  },
  "currencyName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyName"])}
  },
  "currencyCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyCode"])}
  },
  "currencyStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyStatus"])}
  },
  "exchangeRateCurrencies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل تغير العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل تغير العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال معدل العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exchangeRateCurrencies"])}
  },
  "activeStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنشيط الحاله "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeStatus"])}
  },
  "currencySymbol": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencySymbol"])}
  },
  "currencyFormat": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمط العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمط العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال نمط العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyFormat"])}
  },
  "save": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])}
  },
  "remove": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove"])}
  },
  "areYouSure": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متاكد؟"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areYouSure"])}
  },
  "revertThis": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن استرجاع هذا العنصر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revertThis"])}
  },
  "deleteIt": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم احذف هذا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleteIt"])}
  },
  "seen": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاهدة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seen"])}
  },
  "to": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "from": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])}
  },
  "records": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["records"])}
  },
  "first": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاول"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first"])}
  },
  "last": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاخير"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last"])}
  },
  "fields": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجالات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fields"])}
  },
  "fieldsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه حقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsCreate"])}
  },
  "fieldsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل حقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsUpdate"])}
  },
  "fieldsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن المجال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsInfo"])}
  },
  "fieldName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجال"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم المجال "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsName"])}
  },
  "fieldStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله المجال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله المجال"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال حاله المجال "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsStatus"])}
  },
  "sectors": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاعات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectors"])}
  },
  "sectorsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه قطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه قطاع"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsCreate"])}
  },
  "sectorsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل قطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل قطاع"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsUpdate"])}
  },
  "sectorsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن القطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن القطاع"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsInfo"])}
  },
  "sectorsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القطاع"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم القطاع  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsName"])}
  },
  "sectorsStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله القطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله القطاع"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال حاله القطاع "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsStatus"])}
  },
  "samples": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نماذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samples"])}
  },
  "samplesName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النموذج"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم النموذج  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesName"])}
  },
  "samplesStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله النموذج"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال حاله النموذج  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesStatus"])}
  },
  "samplesSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز النموذج"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال رمز النموذج  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesSlug"])}
  },
  "samplesCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود النموذج"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال كود النموذج  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesCode"])}
  },
  "samplesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء نموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesCreate"])}
  },
  "samplesUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesUpdate"])}
  },
  "samplesInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesInfo"])}
  },
  "units": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["units"])}
  },
  "unitsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء وحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsCreate"])}
  },
  "unitsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الوحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsUpdate"])}
  },
  "unitsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الوحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsInfo"])}
  },
  "unitsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوحده"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  اسم الوحده "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsName"])}
  },
  "unitsSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الوحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الوحده"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  رمز الوحده "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsSlug"])}
  },
  "unitsStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الوحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الوحده"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  حاله الوحده "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsStatus"])}
  },
  "pleaseWait": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك انتظر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseWait"])}
  },
  "active": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفعل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])}
  },
  "inactive": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مفعل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive"])}
  },
  "update": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update"])}
  },
  "basicData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الاساسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicData"])}
  },
  "productSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productSettings"])}
  },
  "productInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productInfo"])}
  },
  "modelUnit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النماذج والوحدات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelUnit"])}
  },
  "paymentSystem": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام الدفع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paymentSystem"])}
  },
  "packages": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباقات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packages"])}
  },
  "packagesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء الباقات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesCreate"])}
  },
  "packageUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الباقة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packageUpdate"])}
  },
  "packagesName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقه"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  اسم الباقه  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesName"])}
  },
  "packagesSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الباقه"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  رمز الباقه  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesSlug"])}
  },
  "packagesStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الباقه"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  حاله الباقه  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesStatus"])}
  },
  "packagesUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدات الخاصه بالباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدات الخاصه بالباقه"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  الوحدات لخاصه بالباقه  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesUnits"])}
  },
  "packagesInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesInfo"])}
  },
  "packagesUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesUpdate"])}
  },
  "UltimateSolutions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركة الحلول النهائية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UltimateSolutions"])}
  },
  "otherSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات اخرى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otherSettings"])}
  },
  "productName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productName"])}
  },
  "productStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله لمنتج "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productStatus"])}
  },
  "domain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدومين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["domain"])}
  },
  "language": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])}
  },
  "ar": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar"])}
  },
  "en": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنجليزية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
  },
  "clientCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود العميل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientCode"])}
  },
  "tradeName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم التجاري"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tradeName"])}
  },
  "productLogo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعارالمنتج Logo"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productLogo"])}
  },
  "shoppingCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد التسوق"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shoppingCountry"])}
  },
  "additionalInformation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات اضافية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additionalInformation"])}
  },
  "dropFiles": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم باسقاط الملف هنا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dropFiles"])}
  },
  "yes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])}
  },
  "no": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])}
  },
  "fromModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انطلاقا من نموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fromModel"])}
  },
  "chooseUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الوحدات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fromModel"])}
  },
  "MCQ": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسألة بخيارات MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ"])}
  },
  "exam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam"])}
  },
  "HR": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموارد البشرية HR"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR"])}
  },
  "library": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتبة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["library"])}
  },
  "forum": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتديات Forum "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forum"])}
  },
  "chooseModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseModel"])}
  },
  "ZidneyAcademySkills": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زدني أكاديمية, زدني مهارات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZidneyAcademySkills"])}
  },
  "chooseBasicData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر البيانات الأساسية "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseBasicData"])}
  },
  "division": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعبه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["division"])}
  },
  "seasons": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفصول"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seasons"])}
  },
  "materials": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["materials"])}
  },
  "categories": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيفات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categories"])}
  },
  "other": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])}
  },
  "productOwnership": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملكية المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productOwnership"])}
  },
  "specialZidney": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خاص بزدني "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialZidney"])}
  },
  "specificCustomer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خاص بعميل B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specificCustomer"])}
  },
  "productZidneyPayment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" منتج تابع لنظام الدفع زدني "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specificCustomer"])}
  },
  "commissionZidney": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمولة زدني عن كل مستخدم "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionZidney"])}
  },
  "limitedContentSet": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات المحتوى المحدود"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limitedContentSet"])}
  },
  "contain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحتوى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contain"])}
  },
  "limited": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محدود"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limited"])}
  },
  "unlimited": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير محدود"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unlimited"])}
  },
  "basicDataName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البيانات الاساسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البيانات الاساسية"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  اسم البيانات الاساسية "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataName"])}
  },
  "basicDataSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز البيانات الاساسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز البيانات الاساسية"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  رمز البيانات الاساسية "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataSlug"])}
  },
  "basicDataStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله البيانات الاساسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله البيانات الاساسية"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  حاله البيانات الاساسية "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataStatus"])}
  },
  "basicDataCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء بيانات اساسيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataCreate"])}
  },
  "basicDataShowInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن بيانات اساسيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataShowInfo"])}
  },
  "basicDataUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل بيانات اساسيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataUpdate"])}
  },
  "lamitedContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الاقصي للمحتوي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الاقصي للمحتوي"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  الحد االاقصي للمحتوي   "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lamitedContent"])}
  },
  "clientProducts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientProducts"])}
  },
  "products": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products"])}
  },
  "productPrices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسعار المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productPrices"])}
  },
  "clientsB2B": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركاء B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientsB2B"])}
  },
  "partnersB2B": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركاء B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partnersB2B"])}
  },
  "individualsPartners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركاء أفراد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individualsPartners"])}
  },
  "name": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])}
  },
  "status": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لادخال الحاله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])}
  },
  "email": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])}
  },
  "type": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])}
  },
  "target": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطابع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["target"])}
  },
  "phone": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])}
  },
  "address": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])}
  },
  "create": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create"])}
  },
  "clientName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العميل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientName"])}
  },
  "webSite": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الانترنت"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webSite"])}
  },
  "governmental": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حكومي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["governmental"])}
  },
  "privateSector": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاع خاص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privateSector"])}
  },
  "profit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربحي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profit"])}
  },
  "nonProfit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير ربحي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NonProfit"])}
  },
  "sector": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector"])}
  },
  "areasInterest": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجالات الإهتمام"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areasInterest"])}
  },
  "PromoCodes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرموز الترويجية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PromoCodes"])}
  },
  "PromoCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز الترويجي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PromoCode"])}
  },
  "codeName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الرمز"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["codeName"])}
  },
  "commissionPercentage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة العمولة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionPercentage"])}
  },
  "discountPercentage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الخصم %"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discountPercentage"])}
  },
  "activationStart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية التفعيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationStart"])}
  },
  "activationEnd": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية التفعيل "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationEnd"])}
  },
  "code": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])}
  },
  "branchOf": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خاص بـ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branchOf"])}
  },
  "PromoCodeType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الرمز الترويجي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PromoCodeType"])}
  },
  "general": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])}
  },
  "forCompanies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للشركات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forCompanies"])}
  },
  "forIndividuals": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للأفراد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forIndividuals"])}
  },
  "forSubscribers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للمشتركين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forSubscribers"])}
  },
  "clientType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نوع العميل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forSubscribers"])}
  },
  "users": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])}
  },
  "activated": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفعل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activated"])}
  },
  "notActivated": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مفعل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notActivated"])}
  },
  "clients": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clients"])}
  },
  "dashboard": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dashboard"])}
  },
  "productPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة التسعير"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productPrice"])}
  },
  "addPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة تسعير"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addPrice"])}
  },
  "productsPricesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة - تعديل سعر المنتج "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productsPricesCreate"])}
  },
  "country": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])}
  },
  "currency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency"])}
  },
  "passwordConfirmation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passwordConfirmation"])}
  },
  "from500to2000": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من 500 الي 2000"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from500to2000"])}
  },
  "oneUser": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدم واحد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneUser"])}
  },
  "oneMonth": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneMonth"])}
  },
  "threeMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 اشهر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["threeMonths"])}
  },
  "sixMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 اشهر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sixMonths"])}
  },
  "twelveMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 شهر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["twelveMonths"])}
  },
  "userNumbers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المستخدمين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userNumbers"])}
  },
  "home": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])}
  },
  "organizationalChart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهيكل التنظيمي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizationalChart"])}
  },
  "typesOfWorkTeams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع فرق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typesOfWorkTeams"])}
  },
  "workTeams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workTeams"])}
  },
  "sections": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفصول الدراسيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sections"])}
  },
  "groups": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groups"])}
  },
  "divisions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشُعب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisions"])}
  },
  "subjectsByDivision": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد حسب الشُعب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subjectsByDivision"])}
  },
  "rateCategories": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قِيَم التصنيفات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rateCategories"])}
  },
  "tags": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوسوم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tags"])}
  },
  "commissionName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدائرة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionAR"])}
  },
  "addCommission": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة دائرة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionEN"])}
  },
  "editCommission": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل دائرة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editCommission"])}
  },
  "getStartedTitle": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدأ مع "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getStartedTitle"])}
  },
  "getStartedParagraph": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإنشاء العنصر الأول الخاص بك لبدء بناء قاعدة المعرفة الخاصة بك. "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getStartedParagraph"])}
  },
  "add": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])}
  },
  "import": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استيراد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["import"])}
  },
  "translation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترجمة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation"])}
  },
  "close": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])}
  },
  "default": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سؤال مباشر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["default"])}
  },
  "reset": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reset"])}
  },
  "managingWorkTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اداره فريق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["managingWorkTeam"])}
  },
  "managingWorkTeamName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم اداره فريق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["managingWorkTeamName"])}
  },
  "managingWorkTeamType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["managingWorkTeamType"])}
  },
  "managingWorkTeamNumberOf": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العدد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["managingWorkTeamNumberOf"])}
  },
  "description": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])}
  },
  "typeOfTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الفريق"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typeOfTeam"])}
  },
  "chooseTheUsers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المستخدمين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseTheUsers"])}
  },
  "export": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["export"])}
  },
  "manage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manage"])}
  },
  "all": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])}
  },
  "recent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حديثاََ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recent"])}
  },
  "archived": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مؤرشف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archived"])}
  },
  "filter": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترشيح"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])}
  },
  "columns": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعمدة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["columns"])}
  },
  "find": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["find"])}
  },
  "workTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرق االعمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workTeam"])}
  },
  "typeName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typeName"])}
  },
  "teamType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع فريق"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teamType"])}
  },
  "workTeamName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم  نوع فريق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workTeamName"])}
  },
  "teamName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم   فريق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teamName"])}
  },
  "organizationalChartName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الهيكل التنظيمي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizationalChartName"])}
  },
  "team": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفريق"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "configureSectionsAndGroups": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكوين الاقسام والمجموعات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["configureSectionsAndGroups"])}
  },
  "groupsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجموعه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groupsName"])}
  },
  "sectionsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفصل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectionsName"])}
  },
  "number": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العدد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])}
  },
  "maxNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العدد الأقصى للمنتسبين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxNumber"])}
  },
  "sectionName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفصل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectionName"])}
  },
  "createSection": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء فصل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createSection"])}
  },
  "sectionCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود القسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectionCode"])}
  },
  "sectionType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الفصل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectionType"])}
  },
  "regularSection": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم عادي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["regularSection"])}
  },
  "Subsection": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم فرعي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsection"])}
  },
  "DepartmentBranches": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم أساس له فروع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DepartmentBranches"])}
  },
  "DepartmentSection": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعبة القسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DepartmentSection"])}
  },
  "groupName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجموعة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groupName"])}
  },
  "translate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترجمة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["translate"])}
  },
  "divisionName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشعبة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisionName"])}
  },
  "seasonName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفصل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seasonName"])}
  },
  "materialName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المادة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["materialName"])}
  },
  "materialCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود المادة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["materialCode"])}
  },
  "mixedMaterial": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مادة متعدده اللغات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mixedMaterial"])}
  },
  "categoryName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التصنيف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categoryName"])}
  },
  "category": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["category"])}
  },
  "categoryValue": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة التصنيفات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categoryValue"])}
  },
  "tag": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag"])}
  },
  "userMangment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اداره المستخدمين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userMangment"])}
  },
  "permissionManagement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اداره الصلاحيات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissionManagement"])}
  },
  "usersPermissionManagement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اداره صالحيات المستخدمين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usersPermissionManagement"])}
  },
  "profileName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profileName"])}
  },
  "permissionProfile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحيه البروفيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissionProfile"])}
  },
  "FullName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالكامل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسم بالكامل"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لإدخال الاسم كاملاً مع اللقب"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FullName"])}
  },
  "Gender": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يحدد جنس المستخدم (ذكر / أنثى)"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])}
  },
  "Male": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])}
  },
  "Female": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنثى"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])}
  },
  "Birthday": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يسجل في هذا الحقل تاريخ ميلاد المستخدم من واقع وثيقة الهوية"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])}
  },
  "Address": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدخل المستخدم في هذا الحقل العنوان الواضح لمقر الإقامة"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
  },
  "Address2": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان 2"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان 2"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدخل المستخدم في هذا الحقل العنوان الثانى الواضح لمقر الإقامة"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address2"])}
  },
  "phoneNo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يستخدم الحقل لإدخال رقم الهاتف  الثابت الخاص بالمستخدم"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phoneNo"])}
  },
  "mobileNo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يستخدم الحقل لإدخال رقم الجوال الخاص بالمستخدم"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobileNo"])}
  },
  "Photo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصورة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصورة"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالنقر على زر (اختيار ملف)  Choose File يستعرض النظام المجلدات لاختيار الملف المطلوب فتحه لتحميل"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])}
  },
  "surName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللقب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["surName"])}
  },
  "categoryAndMaterial": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعب و المواد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categoryAndMaterial"])}
  },
  "permissions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصلاحيات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissions"])}
  },
  "subjects": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subjects"])}
  },
  "file": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file"])}
  },
  "upload": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload"])}
  },
  "dragDropText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى سحب وإسقاط ملف من جهاز الكمبيوتر الخاص بك أو النقر للتصفح."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dragDropText"])}
  },
  "affiliates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركات التابعة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["affiliates"])}
  },
  "promoType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الترويج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promoType"])}
  },
  "startDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البداية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startDate"])}
  },
  "endDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endDate"])}
  },
  "relatedTo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابع الى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relatedTo"])}
  },
  "expired": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتهي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expired"])}
  },
  "generateCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء رمز"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generateCode"])}
  },
  "generatePromoCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء رمز ترويجي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generatePromoCode"])}
  },
  "charset": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحرف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["charset"])}
  },
  "example": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example"])}
  },
  "pattern": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pattern"])}
  },
  "questionsBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلات الاسئله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionsBasket"])}
  },
  "MCQsettingsModels": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات ونماذج MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQsettingsModels"])}
  },
  "originalSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعدادات الأصلية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["originalSettings"])}
  },
  "createForms": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء نماذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createForms"])}
  },
  "formManagement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة النماذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formManagement"])}
  },
  "requiredFields": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحقول الإجبارية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requiredFields"])}
  },
  "chooseMaterials": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المواد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseMaterials"])}
  },
  "chooseDivisions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الشُعب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseDivisions"])}
  },
  "questionText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص السؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionText"])}
  },
  "photo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصورة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["photo"])}
  },
  "correctAnswer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجابة الصحيحة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctAnswer"])}
  },
  "oneChoice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيار واحد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneChoice"])}
  },
  "explainAnswer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شرح الإجابة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["explainAnswer"])}
  },
  "basket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basket"])}
  },
  "createModelMCQ": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء نموذج سؤال MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createModelMCQ"])}
  },
  "updateModelMCQ": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل أنموذج سؤال MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updateModelMCQ"])}
  },
  "modelName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelName"])}
  },
  "questionType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع السؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionType"])}
  },
  "normal": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عادي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["normal"])}
  },
  "multiOption": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكثر من خيار صحيح"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["multiOption"])}
  },
  "questionOrdinal": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال ترتيبي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionOrdinal"])}
  },
  "containsImage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال يحتوي على صورة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["containsImage"])}
  },
  "containsInteractive": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال يحتوي على محتوى تفاعلي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["containsInteractive"])}
  },
  "chooseValue": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر القيمة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseValue"])}
  },
  "activeForm": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeForm"])}
  },
  "nameForm": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الأنموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nameForm"])}
  },
  "UQOptions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وحدة الأسئلة خيارات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UQOptions"])}
  },
  "createQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء سؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createQuestion"])}
  },
  "EditQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل سؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EditQuestion"])}
  },
  "createQuestionsBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء سلات الاسئله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createQuestionsBasket"])}
  },
  "fillingBaskets": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملأ السله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fillingBaskets"])}
  },
  "baskets": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baskets"])}
  },
  "basketName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم السله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basketName"])}
  },
  "basketCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود السله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])}
  },
  "maxNumForBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الاقصي لاسئله "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxNumForBasket"])}
  },
  "numberOfQuestionsCurrently": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاسئله حاليا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numberOfQuestionsCurrentlyv"])}
  },
  "branches": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branches"])}
  },
  "generalBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سله عامه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generalBasket"])}
  },
  "relatedBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سله مرتبطه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relatedBasket"])}
  },
  "links": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الروابط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["links"])}
  },
  "basketStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضعيه السله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basketStatus"])}
  },
  "completed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completed"])}
  },
  "pindding": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد التدقيق"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pindding"])}
  },
  "reviewed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد التدفيق"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviewed"])}
  },
  "authenticated": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادق عليها"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticated"])}
  },
  "audited": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدققة "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["audited"])}
  },
  "availableExams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاحة للامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["availableExams"])}
  },
  "availableReview": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاحه للمراجعه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["availableReview"])}
  },
  "questionNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم السؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionNumber"])}
  },
  "questionNote": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات السؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionNote"])}
  },
  "lastEditDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ اخر تعديل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lastEditDate"])}
  },
  "subject": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الماده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subject"])}
  },
  "show": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show"])}
  },
  "chooseQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر السؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseQuestion"])}
  },
  "chooseTheBasket": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر السله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseTheBasket"])}
  },
  "cancelQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء السوال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelQuestion"])}
  },
  "MCQExams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحانات MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQExams"])}
  },
  "examData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examData"])}
  },
  "examSetting": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examSetting"])}
  },
  "theExaminersInterface": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وجهه الممتحنين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["theExaminersInterface"])}
  },
  "downloadQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل الاسئله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["downloadQuestions"])}
  },
  "examStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examStatus"])}
  },
  "directedExamFor": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحان موجه ل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["directedExamFor"])}
  },
  "subscribers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشتركين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscribers"])}
  },
  "visitors": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزوار"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visitors"])}
  },
  "fromSample": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انطلاقا من نماذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fromSample"])}
  },
  "numberOfQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاسئله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numberOfQuestions"])}
  },
  "examName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examName"])}
  },
  "examCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examCode"])}
  },
  "examDurationWithMinuts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مده الامتحان بالدقايق"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examDurationWithMinuts"])}
  },
  "createUrl": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء رابط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createUrl"])}
  },
  "examType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examType"])}
  },
  "longTimeExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحان دائم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longTimeExam"])}
  },
  "programmedExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحان مجدول"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["programmedExam"])}
  },
  "successExamReult": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علامة الحد الادنى للنجاح"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successExamReult"])}
  },
  "examStart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدايه الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examStart"])}
  },
  "examEnd": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهايه الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examEnd"])}
  },
  "orderOfOptions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب الاسئلة والخيارات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orderOfOptions"])}
  },
  "uniformArrangement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب موحد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uniformArrangement"])}
  },
  "differentOrder": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب مختلف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["differentOrder"])}
  },
  "examResult": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتيجه الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examResult"])}
  },
  "nature": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طبيعة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nature"])}
  },
  "areaOfFocus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منطقة التركيز"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areaOfFocus"])}
  },
  "client": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العميل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client"])}
  },
  "quickInterface": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واجهة سريعة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quickInterface"])}
  },
  "options": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخيارات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["options"])}
  },
  "passOnce": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجتياز مره واحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passOnce"])}
  },
  "examActivationAlert": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبيه تفعيل الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examActivationAlert"])}
  },
  "warningThatTheEndOfTheExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبيه اقتراب نهايه الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warningThatTheEndOfTheExam"])}
  },
  "day": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])}
  },
  "before": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["before"])}
  },
  "messagesOfResults": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائل النتائج التهاني و التشجيعات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["messagesOfResults"])}
  },
  "generalResult": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتيجه العامه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generalResult"])}
  },
  "correctionAfterCompletingTheQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصحيح بعد الانتهاء من السؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctionAfterCompletingTheQuestion"])}
  },
  "detailedResultsAndStatistics": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتائج و الاحصائيات المفصله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detailedResultsAndStatistics"])}
  },
  "verticalView": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض عمودي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verticalView"])}
  },
  "horizontalView": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض افقي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horizontalView"])}
  },
  "motivationalMessages": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائل التحفيز"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["motivationalMessages"])}
  },
  "addMessage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه رساله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addMessage"])}
  },
  "questionOptions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال والخيارات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionOptions"])}
  },
  "note": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["note"])}
  },
  "appearsProfessors": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم يظهر للأساتذة فقط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appearsProfessors"])}
  },
  "classificationQsettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف وإعدادات السؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classificationQsettings"])}
  },
  "statistics": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistics"])}
  },
  "manual": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدوي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manual"])}
  },
  "automatic": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آلي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatic"])}
  },
  "added": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المضافه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["added"])}
  },
  "remaining": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتبقيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining"])}
  },
  "questionsList": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمه االاسئله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionsList"])}
  },
  "examQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسئله امتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examQuestions"])}
  },
  "addExamQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف الاسله المختاره لامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addExamQuestions"])}
  },
  "examsUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وحده امتحانات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examsUnits"])}
  },
  "model": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model"])}
  },
  "modelSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model"])}
  },
  "totalModelPoints": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي علامات النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["totalModelPoints"])}
  },
  "startWithText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع يبدا بنص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startWithText"])}
  },
  "content": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحتوي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content"])}
  },
  "addPart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه جزء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addPart"])}
  },
  "addSubpart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه جزء فرعي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addSubpart"])}
  },
  "partHaveContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجزء يحتوي علي بيان معطيات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partHaveContent"])}
  },
  "nameOfPart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجزء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Of Part"])}
  },
  "managePricesPackages": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الأسعار والباقات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ManagePricesPackages"])}
  },
  "packagesPrices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسعار الباقات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesPrices"])}
  },
  "packageName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packageName"])}
  },
  "platformSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات المنصة "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platformSettings"])}
  },
  "modelUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النموذج والوحدات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelUnits"])}
  },
  "InterfaceManagement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الواجهات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["InterfaceManagement"])}
  },
  "licenseSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseSettings"])}
  },
  "licensingInformation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licensingInformation"])}
  },
  "licenseEndAlerts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبيهات نهاية الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseEndAlerts"])}
  },
  "licenseRenewalApplication": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License renewal application"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseRenewalApplication"])}
  },
  "productType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productType"])}
  },
  "licenseDuration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseDuration"])}
  },
  "licenseNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseNumber"])}
  },
  "licenseStartDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ بداية الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseStartDate"])}
  },
  "licenseEndDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ نهاية الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseEndDate"])}
  },
  "platformName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنصة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platformName"])}
  },
  "platformLogo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعار المنصة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platformLogo"])}
  },
  "defaultDisplayLanguage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لغة العرض التلقائية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defaultDisplayLanguage"])}
  },
  "remainingLicense": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باقي على نهاية الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remainingLicense"])}
  },
  "notificationBeforeEndLicense": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال التنبيه بنهاية الترخيص قبل نهاية الترخيص بـ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notificationBeforeEndLicense"])}
  },
  "frequencyAlerts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تردد التنبيهات كل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defaultDisplayLanguage"])}
  },
  "models": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النماذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["models"])}
  },
  "topic": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موضوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topic"])}
  },
  "topicName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الموضوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topicName"])}
  },
  "topicSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الموضوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topicSlug"])}
  },
  "promoCodesAndPartners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرموز الترويجيه والشركاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promoCodesAndPartners"])}
  },
  "partners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partners"])}
  },
  "promoCodes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرموز الترويجيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promoCodes"])}
  },
  "licensing": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licensing"])}
  },
  "notSubscribed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مشترك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notSubscribed"])}
  },
  "typeOfSubscriber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المشترك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typeOfSubscriber"])}
  },
  "departments": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقسام"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["departments"])}
  },
  "department": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["department"])}
  },
  "noParentDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد اقسام مرتبطه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noParentDepartment"])}
  },
  "departmentType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع القسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["departmentType"])}
  },
  "normalDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم عادى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["normalDepartment"])}
  },
  "subDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم فرعى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subDepartment"])}
  },
  "baseWithBranchesDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم اساسى له فروع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baseWithBranchesDepartment"])}
  },
  "divisionOfDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعبه القسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisionOfDepartment"])}
  },
  "departmentCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود القسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["departmentCode"])}
  },
  "questionMultiMedia": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال يحتوي علي مالتي ميديا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionMultiMedia"])}
  },
  "activeRevice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفعل للمراجعة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeRevice"])}
  },
  "activeExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفعل للامتحانات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeExam"])}
  },
  "specialActive": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل خاص للمراجعة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialActive"])}
  },
  "specialActiveExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل خاص للامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialActive"])}
  },
  "addTrueCheck": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ضع علامة صح "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addTrueCheck"])}
  },
  "featured": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مميز "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["featured"])}
  },
  "questions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الاسئلة بخيارات "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questions"])}
  },
  "link_basket_questions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربط الاسئلة بالسلة "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["link_basket_questions"])}
  },
  "next": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])}
  },
  "backOfficeTrialVersion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد مستخدمين ال back office النسخه التجربيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backOfficeTrialVersion"])}
  },
  "questionsTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نماذج الامتحانات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionsTemplates"])}
  },
  "putMark": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضع علامه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["putMark"])}
  },
  "chooseMaterial": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المادة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseMaterial"])}
  },
  "typeOfWorkTeams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع فرق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typeOfWorkTeams"])}
  },
  "main": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رئيسيي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["main"])}
  },
  "topicCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود الموضوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topicCode"])}
  },
  "modelHeader": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقدمة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelHeader"])}
  },
  "modelFooter": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخاتمة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelFooter"])}
  },
  "direct": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مباشر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["direct"])}
  },
  "choice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الاختيار من متعدد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choice"])}
  },
  "descriptionAnswer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" وصف الاجابه "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descriptionAnswer"])}
  },
  "checkMark": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تحديد الاجابه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkMark"])}
  },
  "questionContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محتوى السؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionContent"])}
  },
  "notes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notes"])}
  },
  "mark": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mark"])}
  },
  "enableExport": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل التصدير"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enableExport"])}
  },
  "Exercises": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التمارين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exercise"])}
  },
  "exerciseTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نماذج التمارين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exerciseTemplate"])}
  },
  "exerciseUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وحدة التمارين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exerciseUnits"])}
  },
  "exerciseName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التمارين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exerciseName"])}
  },
  "exerciseSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود التمارين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exerciseSlug"])}
  },
  "departmentName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["departmentName"])}
  },
  "subjectName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المادة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subjectName"])}
  },
  "tagName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tagName"])}
  },
  "statusChanged": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تغير الحالة بنجاح"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statusChanged"])}
  },
  "chooseThesubscribers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر مشتركين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseThesubscribers"])}
  },
  "transferToAnotherDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقل إلى قسم آخر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transferToAnotherDepartment"])}
  },
  "youShouldChooseDifferentDepartment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك اختيار قسم مختلف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["youShouldChooseDifferentDepartment"])}
  },
  "exams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأمتحانات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exams"])}
  },
  "roleName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البروفايل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roleName"])}
  },
  "transferToAnotherTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقل الي فريق عمل اخر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transferToAnotherTeam"])}
  },
  "youShouldChooseDifferentTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان تختار فريق عمل مختلف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["youShouldChooseDifferentTeam"])}
  },
  "pointsAndRates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقاط والمعدلات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pointsAndRates"])}
  },
  "total": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])}
  },
  "fullMark": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلامة الكاملة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fullMark"])}
  },
  "generalRate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل عام"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generalRate"])}
  },
  "dotting": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنقيط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dotting"])}
  },
  "factor": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معامل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factor"])}
  },
  "dottingPattern": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمط التنقيط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dottingPattern"])}
  },
  "percentage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة مئوية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage"])}
  },
  "salesAndSubscriptions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبيعات والإشتراكات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salesAndSubscriptions"])}
  },
  "sales": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبيعات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sales"])}
  },
  "subscriptions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإشتراكات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriptions"])}
  },
  "dateRegistration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التسجيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dateRegistration"])}
  },
  "activation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفعيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activation"])}
  },
  "activationDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التفعيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationDate"])}
  },
  "userRegistration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المستخدم عند التسجيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userRegistration"])}
  },
  "freeSubscription": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشتراك مجاني"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["freeSubscription"])}
  },
  "userName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userName"])}
  },
  "packagePrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الباقة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagePrice"])}
  },
  "paymentPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الدفع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paymentPrice"])}
  },
  "identificationNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم التعريف ID"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identificationNumber"])}
  },
  "subscriberActivation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل المشترك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriberActivation"])}
  },
  "subscriberOrganization": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنظيم المشترك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriberOrganization"])}
  },
  "marked": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم وضع علامة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marked"])}
  },
  "excelFile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف الاكسل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excelFile"])}
  },
  "downloadSimple": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل مثال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["downloadSimple"])}
  },
  "fileIsReq": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف مطلوب."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fileIsReq"])}
  },
  "price": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])}
  },
  "activationCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التفعيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationCode"])}
  },
  "oneSubscriber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشترك واحد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneSubscriber"])}
  },
  "subscriberGroup": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة مشتركين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriberGroup"])}
  },
  "choose": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose"])}
  },
  "subscriber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشترك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscriber"])}
  },
  "deservedAmount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المستحق"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deservedAmount"])}
  },
  "paymentMethod": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة المخالصة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paymentMethod"])}
  },
  "electronic": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكتروني"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["electronic"])}
  },
  "namePayer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدافع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["namePayer"])}
  },
  "confirmPayment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد المخالصة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmPayment"])}
  },
  "cancelPayment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء المخالصة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelPayment"])}
  },
  "paymentNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المخالصة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paymentNumber"])}
  },
  "automaticActivation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل آلي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automaticActivation"])}
  },
  "donePayment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت المخالصة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donePayment"])}
  },
  "defaultLanguage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغه الافتراضيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defaultLanguage"])}
  },
  "grade": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علامه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grade"])}
  },
  "percent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبه مئويه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percent"])}
  },
  "factory": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعامل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["factory"])}
  },
  "mainClassification": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيف الرئيسي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mainClassification"])}
  },
  "notVisibleToSubscribers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تظهر للمشتركين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notVisibleToSubscribers"])}
  },
  "pending": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد الانتظار"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])}
  },
  "localCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملة محلية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["localCurrency"])}
  },
  "foreignCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملة أجنبية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foreignCurrency"])}
  },
  "discountPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر بعد الخصم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discountPrice"])}
  },
  "months": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهور"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months"])}
  },
  "messageTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قوالب الرسائل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["messageTemplates"])}
  },
  "createMessageTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء قالب رسائل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createMessageTemplates"])}
  },
  "editMessageTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل قالب رسائل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editMessageTemplates"])}
  },
  "nameMessageTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم قالب الرسالة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nameMessageTemplate"])}
  },
  "confirmed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مؤكدة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmed"])}
  },
  "canceled": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملغية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canceled"])}
  },
  "trash": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سله المهملات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trash"])}
  },
  "searchErrorMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي ادخال ثلاث حروف علي الأقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["searchErrorMsg"])}
  },
  "fullExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fullExam"])}
  },
  "mcqExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحان MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcqExam"])}
  },
  "exercise": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تدريب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exercise"])}
  },
  "exerciseCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود التمرين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exerciseCode"])}
  },
  "failMessage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة رسوب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["failMessage"])}
  },
  "successMessage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة نجاح"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successMessage"])}
  },
  "motavMessage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة تحفيز"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["motavMessage"])}
  },
  "message": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["message"])}
  },
  "mcq_exam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ اختبار"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcq_exam"])}
  },
  "mcqHistory": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل التعديلات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcqHistory"])}
  },
  "libraries": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتبات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["libraries"])}
  },
  "typesOfFiles": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع الملفات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typesOfFiles"])}
  },
  "extensionsOfFiles": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صيغه الملفات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extensionsOfFiles"])}
  },
  "files": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملفات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["files"])}
  },
  "topics": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواضيع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topics"])}
  },
  "remainingPoints": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Points"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remainingPoints"])}
  },
  "NOfPoints": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N.of Points"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOfPoints"])}
  },
  "questionTitle": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question Title"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questionTitle"])}
  },
  "criterion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criterion"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["criterion"])}
  },
  "addCriterion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Criterion"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCriterion"])}
  },
  "totalPoints": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة الكلية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["totalPoints"])}
  },
  "autoGenerate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auto Generate"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autoGenerate"])}
  },
  "standers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["standers"])}
  },
  "pleaseChooseTopicModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لطفاً قم باختيار نموذج الموضوع."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseChooseTopicModel"])}
  },
  "pleaseChooseEcerciseModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لطفاً قم باختيار نموذج التمرين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseChooseEcerciseModel"])}
  },
  "getStartedTopic": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للبدء في إنشاء موضوعك، اتخذ الخطوة الأولى عن طريق تحديد نموذج."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getStartedTopic"])}
  },
  "getStartedExercise": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للبدء في إنشاء تمرينك، اتخذ الخطوة الأولى عن طريق تحديد نموذج."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getStartedExercise"])}
  },
  "createTopic": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Topic"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createTopic"])}
  },
  "divistions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divistions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divistions"])}
  },
  "singleChoose": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Choose"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["singleChoose"])}
  },
  "multiChoose": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi Choose"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["multiChoose"])}
  },
  "INFO": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO"])}
  },
  "customActivation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التنشيط المخصص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customActivation"])}
  },
  "answers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجابات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answers"])}
  },
  "addAnswer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة إجابة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addAnswer"])}
  },
  "addNote": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة ملاحظة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addNote"])}
  },
  "createQuestionModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء اسئله النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createQuestionModel"])}
  },
  "singleOption": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار واحد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["singleOption"])}
  },
  "history": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["history"])}
  },
  "data": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data"])}
  },
  "innerSection": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سكشن داخلي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innerSection"])}
  },
  "showQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معاينة السؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["showQuestion"])}
  },
  "errorFound": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد خطاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["errorFound"])}
  },
  "and": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["و"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])}
  },
  "specialContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محتوي خاص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialContent"])}
  },
  "extensionsOfFile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صيغه الملف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extensionsOfFile"])}
  },
  "typesOfFile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الملف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typesOfFile"])}
  },
  "open": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتوح"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["open"])}
  },
  "lessons": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدروس"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lessons"])}
  },
  "lessonName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدرس"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lessonName"])}
  },
  "contain_certificat": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يحتوي علي شهاده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contain_certificat"])}
  },
  "certificates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificates"])}
  },
  "lesson": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرس"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lesson"])}
  },
  "certificateName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشهاده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificateName"])}
  },
  "uploadBackgroundImage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل خلفيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploadBackgroundImage"])}
  },
  "certificate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهاده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificate"])}
  },
  "selectMsgTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر قالب الرساله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selectMsgTemplate"])}
  },
  "time": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time"])}
  },
  "date": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])}
  },
  "startTime": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت البداية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startTime"])}
  },
  "answerCorrectOrdering": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب التصحيح "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answerCorrectOrdering"])}
  },
  "template": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القالب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["template"])}
  },
  "complateCondition": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الاكتمال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complateCondition"])}
  },
  "answerOrdering": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تـرتـيـب الاجابة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answerOrdering"])}
  },
  "answer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجابة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answer"])}
  },
  "infoAboutQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن السؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["infoAboutQuestion"])}
  },
  "degreeOfQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجه السؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["degreeOfQuestion"])}
  },
  "tracks": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسارات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tracks"])}
  },
  "track": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسار"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["track"])}
  },
  "instructions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توجيهات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instructions"])}
  },
  "instruction": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توجيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instruction"])}
  },
  "review": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review"])}
  },
  "evaluation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluation"])}
  },
  "solveTopics": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حل مواضيع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solveTopics"])}
  },
  "ads": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشهارات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ads"])}
  },
  "systemReports": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبليغات النظام"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["systemReports"])}
  },
  "userReports": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبليغات المستخدمين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userReports"])}
  },
  "reportsTypes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع التبليغات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reportsTypes"])}
  },
  "question": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سؤال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["question"])}
  },
  "feedbacks": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الآراء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedbacks"])}
  },
  "feedbackTypes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع الآراء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedbackTypes"])}
  },
  "feedbackUserExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اراء مستخدم الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedbackUserExam"])}
  },
  "feedbacZideny": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اراء زدني"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedbacZideny"])}
  },
  "comments": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليقات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comments"])}
  },
  "screenshot": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صوره"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["screenshot"])}
  },
  "lives": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lives"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lives"])}
  },
  "live": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["live"])}
  },
  "duration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duration"])}
  },
  "link": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["link"])}
  },
  "student": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطالب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student"])}
  },
  "assessments": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييمات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assessments"])}
  },
  "scheduledExams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحانات مجدوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheduledExams"])}
  },
  "suggestedDuration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المده المقترحه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suggestedDuration"])}
  },
  "beforeYouStart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبل ان يبداء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beforeYouStart"])}
  },
  "oneTimeOttempt": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاوله واحده فقط."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneTimeOttempt"])}
  },
  "reviewingAnswerDuringTheExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعه الاجابه اثناء الامتحان."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviewingAnswerDuringTheExam"])}
  },
  "shareableCertificateOfCompletion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اظهار النتيجه بعد لامتحان."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shareableCertificateOfCompletion"])}
  },
  "examinerMode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الممتحن"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examinerMode"])}
  },
  "relaxMode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدون توقيت"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relaxMode"])}
  },
  "chronoMode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بتوقيت"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chronoMode"])}
  },
  "start": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابداء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])}
  },
  "minutes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دقائق"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])}
  },
  "continue": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يستمر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continue"])}
  },
  "startingIn": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يبدا في .."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startingIn"])}
  },
  "relax": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استجم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relax"])}
  },
  "unansweredQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسئله متخطاه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unansweredQuestions"])}
  },
  "favoritQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسئله مميزه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["favoriteQuestions"])}
  },
  "anythingElseYouLikeAdd": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هناك أي شيء آخر تود إضافته؟"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anythingElseYouLikeAdd"])}
  },
  "howWouldYouRateYourExperience": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف تقيم تجربتك؟"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["howWouldYouRateYourExperience"])}
  },
  "doYouFeelLikeTestSccuratelyMeasuredYourSkills": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تشعر بأن الاختبار يقيس مهاراتك بدقة؟"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doYouFeelLikeTestSccuratelyMeasuredYourSkills"])}
  },
  "fantastic": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رائع!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fantastic"])}
  },
  "tryAgain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاول ثانية!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tryAgain"])}
  },
  "fantasticMesg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل عظيم على الحصول على حق! استمروا في الزخم!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fantasticMesg"])}
  },
  "tryAgainMesg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يستمر في التقدم! الأخطاء تحدث، ولكن التعلم كذلك."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tryAgainMesg"])}
  },
  "noUnansweredMarkedOrFavoriteFound": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على أسئلة تم تخطيها أو تحديدها."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noUnansweredMarkedOrFavoriteFound"])}
  },
  "learningRecommendations": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توصيات التعلم."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learningRecommendations"])}
  },
  "learningRecommendationsText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعتمد هذه التوصيات المخصصة على فجوات المهارات لديك. ابدأ في الحصول على واحدة الآن لتحسين مهاراتك."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learningRecommendationsText"])}
  },
  "summary": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["summary"])}
  },
  "CompletingExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إكمال الامتحان."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CompletingExam"])}
  },
  "HoldItRightThereDes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد قمت بالإجابة على الأسئلة ووضع علامات عليها. يمكنك مراجعتها قبل إكمال الامتحان."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HoldItRightThereDes"])}
  },
  "HoldItRightThere": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توقف هنا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HoldItRightThere"])}
  },
  "youScoredBetterThan": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد حصلت على نتيجة أفضل من"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["youScoredBetterThan"])}
  },
  "ofAllExamTakers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من جميع المتقدمين للامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ofAllExamTakers"])}
  },
  "unansweredAndMarkedQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة بلا إجابة ومُشار إليها"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unansweredAndMarkedQuestions"])}
  },
  "CongratulationsPRag": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبروك لقد تجاوزت المعدل المطلوب."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CongratulationsPRag"])}
  },
  "mcq": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار من متعدد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcq"])}
  },
  "testYourself": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختبر نفسك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["testYourself"])}
  },
  "discourse": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discourse"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discourse"])}
  },
  "rushMode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rush Mode"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rushMode"])}
  },
  "zidneyExams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zidney Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zidneyExams"])}
  },
  "correctionMethod": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة التصحيح"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctionMethod"])}
  },
  "AI": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI"])}
  },
  "Manually": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدويا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually"])}
  },
  "score": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتيجة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["score"])}
  },
  "fail": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يفشل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fail"])}
  },
  "youNotAnswerThisQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تجيب على هذه الأسئلة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["youNotAnswerThisQuestion"])}
  },
  "viewUnansweredQuestions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الأسئلة التي لم تتم الإجابة عليها"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viewUnansweredQuestions"])}
  },
  "books": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["books"])}
  },
  "previousView": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظرة سابقة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["previousView"])}
  },
  "Library": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتبه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])}
  },
  "discover": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المميزه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discover"])}
  },
  "noContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد محتوي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noContent"])}
  },
  "learningPath": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسارات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learningPath"])}
  },
  "greatToSeeYouAgain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعيد برؤيتك مجددا!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["greatToSeeYouAgain"])}
  },
  "resume": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيرة ذاتية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resume"])}
  },
  "discoverPaths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مميزه المسارات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discoverPaths"])}
  },
  "yourPaths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساراتك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yourPaths"])}
  },
  "path": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسارك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["path"])}
  },
  "name_": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name_"])}
  },
  "createYourPath": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء مسارك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createYourPath"])}
  },
  "assign": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assign"])}
  },
  "yourPath": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسارك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yourPath"])}
  },
  "username": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])}
  },
  "institutionalData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المؤسسة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["institutionalData"])}
  },
  "mailAndNotifications": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البريد و التنبيهات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mailAndNotifications"])}
  },
  "shareContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مشاركة المحتوى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shareContent"])}
  },
  "report": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التبليغ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report"])}
  },
  "usersManagement": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اداره المستخدمين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usersManagement"])}
  },
  "leaveFeedback": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اخبرنا تقيمك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leaveFeedback"])}
  },
  "permission": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصالحيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permission"])}
  },
  "user": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])}
  },
  "questions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسئله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questions"])}
  },
  "createdDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانشاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createdDate"])}
  },
  "management": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أداره"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["management"])}
  },
  "usersPermissions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أداره الصالحيات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usersPermissions"])}
  },
  "addUsersToDepartments": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه مستخدمين الى القسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addUsersToDepartments"])}
  },
  "createdBy": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنشات ب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createdBy"])}
  },
  "addPermissionData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة بيانات الصلاحيات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addPermissionData"])}
  },
  "true": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true"])}
  },
  "false": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["false"])}
  },
  "typeOf": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typeOf"])}
  },
  "checkStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجاء مراجعة الحالة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkStatus"])}
  },
  "mcqStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcqStatus"])}
  },
  "containCertificate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحتوي على شهادة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["containCertificate"])}
  },
  "Exercisestatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة التمرين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercisestatus"])}
  },
  "success": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناجح"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["success"])}
  },
  "topicStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الموضوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["topicStatus"])}
  },
  "correctionAfterCompletingTheExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصحيح بعد الانتهاء من الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctionAfterCompletingTheExam"])}
  },
  "fileOrVideo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف أو فيديو"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر ملفاً أو فيديو"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد إما رفع ملف من جهازك أو إدخال رابط فيديو."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fileOrVideo"])}
  },
  "uploadFile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع ملف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع ملف"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدم هذا الخيار لرفع ملف من جهازك."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploadFile"])}
  },
  "videoUrl": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط فيديو"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رابط فيديو"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإدخال رابط صحيح للفيديو لإضافته في المحتوى."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["videoUrl"])}
  },
  "fileOrVideoRequired": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى تقديم ملف أو رابط فيديو."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف أو فيديو مطلوب"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكد من رفع ملف أو إدخال رابط فيديو قبل المتابعة."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fileOrVideoRequired"])}
  },
  "invalidUrl": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط المدخل غير صحيح."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط غير صحيح"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى التحقق من صيغة الرابط والمحاولة مرة أخرى."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalidUrl"])}
  },
  "videoPreview": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معاينة الفيديو"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معاينة الفيديو"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه معاينة للفيديو بناءً على الرابط الذي أدخلته."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["videoPreview"])}
  },
  "duplicate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخة طبق الأصل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duplicate"])}
  },
  "timingAlert": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبيه بالوقت"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timingAlert"])}
  },
  "thisExamWillEndAfter": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الامتحان سوف ينتهي بعد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thisExamWillEndAfter"])}
  },
  "days": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أيام"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])}
  },
  "TheExercises": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التمارين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TheExercises"])}
  },
  "Exercise": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمرين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise"])}
  },
  "activeExercise": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفعل للتمرين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeExercise"])}
  },
  "ExerciseDuration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة التمرين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ExerciseDuration"])}
  },
  "ExerciseStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة التمرين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ExerciseStatus"])}
  },
  "finishTheExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنهاء الامتحان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finishTheExam"])}
  },
  "waitAssessment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الانتظار بينما نقوم بإعداد تقييمك!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waitAssessment"])}
  },
  "AssessmentShortly": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيبدأ تقييمك قريبًا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AssessmentShortly"])}
  },
  "goodLuck": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حظ سعيد!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["goodLuck"])}
  },
  "errorPageMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آسف! الصفحة التي تبحث عنها غير موجودة."])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["errorPageMsg"])}
  },
  "goBackToHome": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجوع للصفحه الرئيسيه"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["goBackToHome"])}
  },
  "mcqAssessment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار من متعدد - التقييم"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mcqAssessment"])}
  },
  "noRushJustFocus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا داعي للاستعجال، فقط التركيز!"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noRushJustFocus"])}
  },
  "flaggedQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سؤال مميز"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["flaggedQuestion"])}
  },
  "settings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settings"])}
  },
  "submitAnswers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعتماد الاجابات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submitAnswers"])}
  },
  "pleaseEnterTheStandardsOfYourExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال معايير امتحانك"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseEnterTheStandardsOfYourExam"])}
  },
  "downloadWord": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا مستند وورد. انقر أدناه للتنزيل:"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["downloadWord"])}
  },
  "download": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنزيل المستند"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download"])}
  },
  "correctionOfAnswers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصحيح الاسئله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctionOfAnswers"])}
  },
  "correctOrder": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترتيب الصحيح:"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correctOrder"])}
  },
  "waitingForResults": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنتظر اجابتك!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waitingForResults"])}
  },
  "Loading": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل..."])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])}
  },
  "checkTheAnswer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شرح الاجابه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkTheAnswer"])}
  },
  "nextQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال التاني"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nextQuestion"])}
  },
  "finishTheQuestion": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانتهاء من الاسئله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finishTheQuestion"])}
  },
  "timeOfExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Of Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timeOfExam"])}
  },
  "QuestionsOfExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions Of Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QuestionsOfExam"])}
  },
  "ScoreOfExam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Of Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ScoreOfExam"])}
  },
  "pleaseMoveTheAnswersToReorderThem": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك قم بتحريك الاجابات لكي تقوم بإعادة ترتيبها"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseMoveTheAnswersToReorderThem"])}
  }
}